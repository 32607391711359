const en = {
    open: 'Open',
    save: 'Save',
    undo: 'Undo',
    clear: 'Clear',
    help: 'Help',
    donate: 'Donate',
    confirm: 'Confirm',
    delete: 'Delete',
    insert: 'Insert Next',
    merge: 'Merge Next',

    'open-subtitle': 'Open Subtitle',
    'open-subtitle-supports': 'Supports opening .vtt, .srt and .ass subtitle',
    'open-subtitle-success': 'Open subtitles successfully',
    'open-subtitle-error': 'Failed to open subtitles',

    'open-video': 'Open Video',
    'open-video-supports': 'Supports opening .mp4, .webm and .ogg video',
    'open-video-warning':
        'The files are opened locally and no data is uploaded. When creating an audio waveform, the browser may be blocked for a short time due to audio decoding, so it is not suitable for opening too large video files.',
    'open-video-success': 'Open video successfully',
    'open-video-error': 'Failed to open video',

    'clear-warning': 'This step cannot be rolled back. Are you sure ?',
    'clear-success': 'Clear all subtitles data successfully',

    'help-info':
        'This editor is suitable for video with small volume and simple subtitle effect. For large file videos or more subtitle effect, please use professional desktop software.',
    'help-issue': 'Open subsepak',
    'help-email': ' ',

    'donate-info':
        'Or you can buy me a cup of coffee to encourage me to continue to update and improve the subtitle editor.',
    'donate-weChatPay': 'WeChat Pay:',
    'donate-alipay': 'Alipay:',

    'history-rollback': 'History rollback successful',
    'history-empty': 'History is empty',

    'translation-success': 'Translation successful',
    'translation-progress': 'Translation in progress',
    'translation-limit': 'Limit 1000 translations per batch',

    waveform: 'Waveform:',
    size: 'Size:',
    decode: 'Decode:',
    channel: 'Channel:',
    duration: 'Duration:',
    zoom: 'Zoom:',
    metronome: 'Metronome:',
    export: 'Export',
    'parameter-error': 'Parameter error',
    'keep-one': 'Please keep at least one subtitle',
    'subtitle-text': '[Subtitle Text]',
    'time-offset': 'Time Offset:',
    'google-translate': 'Google Translate:',
    'subtitle-style': 'Subtitle Style:',
    'subtitle-size': 'Size',
    'subtitle-bottom': 'Bottom',
    'subtitle-color': 'Color',
    'auto-align': 'Auto Align:',
    'loop-playback': 'Loop Playback:',
};

const zh = {
    open: '打开',
    save: '保存',
    undo: '回退',
    clear: '清除',
    help: '帮助',
    donate: '捐助',
    confirm: '确定',

    delete: '删除',
    insert: '插入下一条',
    merge: '合并下一条',

    'open-subtitle': '打开字幕',
    'open-subtitle-supports': '支持打开 .vtt, .srt 和 .ass 字幕',
    'open-subtitle-success': '打开字幕成功',
    'open-subtitle-error': '打开字幕失败',

    'open-video': '打开视频',
    'open-video-supports': '支持打开 .mp3 .wav .mp4, .webm 和 .ogg 音视频文件',
    'open-video-warning':
        '请将您在字幕说生成的音频及字幕文件导入，您也可以上传自己的视频或音频文件，所有文件均不会被上传。创建音频波形时，浏览器可能会由于音频解码而在短时间内被阻塞，所以不适合打开过大的视频文件。',
    'open-video-success': '打开视频成功',
    'open-video-error': '打开视频失败',

    'clear-warning': '此步骤无法回退，确定吗?',
    'clear-success': '清除所有字幕数据成功',

    'help-info': '该编辑器适合编辑您从字幕说生成音频、字幕文件，方便您对字幕文件进行修改或者增删字幕。字幕导出格式为vtt格式，将尽快支持srt字幕，您可以通过其他在线工具进行转换或直接导入到编辑软件中。工具免费开放。',
    'help-issue': '访问字幕说：',
    'help-email': '',

    'donate-info': '0',
    'donate-weChatPay': '1',
    'donate-alipay': '2',

    'history-rollback': '历史回退成功',
    'history-empty': '历史是空的',

    'translation-success': '翻译成功',
    'translation-progress': '翻译正在进行',
    'translation-limit': '每次限制1000条翻译',

    waveform: '波形图:',
    size: '大小:',
    decode: '解码:',
    channel: '声道:',
    duration: '时长:',
    zoom: '缩放:',
    metronome: '节拍器:',
    export: '导出',
    'parameter-error': '参数错误',
    'keep-one': '请至少保留一个字幕',
    'subtitle-text': '[字幕文字]',
    'time-offset': '时间偏移:',
    'google-translate': '谷歌翻译:',
    'subtitle-style': '字幕样式:',
    'subtitle-size': '大小',
    'subtitle-bottom': '底部',
    'subtitle-color': '颜色',
    'auto-align': '自动对齐:',
    'loop-playback': '循环播放:',
};

export default {
    en,
    zh,
    'zh-cn': zh,
    'zh-tw': zh,
};

export const names = {
    en: 'EN',
    zh: '中',
};

export const getName = (key) => {
    return (
        {
            en: 'en',
            zh: 'zh',
            'zh-cn': 'zh',
            'zh-tw': 'zh',
        }[key] || 'en'
    );
};
